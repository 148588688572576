import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useContext, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { CheckBoxReferralIcon } from '../../../assets';
import { RefetchLateral } from '../../../context/refetchLateral/refetchProvider';
import { UPDATE_CAMPAIGN } from '../../../graphql/campaign/mutation';
import { GET_SETTINGS } from '../../../graphql/campaign/query';
import { useNotification } from '../../../hooks/useNotification';
import { usePrompt } from '../../../hooks/usePrompt';
import { Button } from '../../common/Button';
import { DatePickerInput } from '../../inputs/DatePicker';
import { MoneyInput } from '../../inputs/MoneyInput';
import { TextInput } from '../../inputs/TextInput';
import { UserDropdown } from '../../inputs/UserDropdown';
import { UserDropdownList } from '../../inputs/UserDropdownList';
import DescriptionPlatforms from '../settings/Description';
import DisclaimerPlatforms from '../settings/Disclaimer';
// import SelectInfluencer from '../settings/SelectInfluencer';

// import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015

dayjs.extend(customParseFormat);

const CUSTOM_BUTTON_CLASSNAMES = '!text-influentia-light';

type Settings = {
  campaignSettings: {
    data: {
      id: string;
      month?: string | Date;
      name: string;
      money: number;
      currency: string;
      brand: {
        id: string;
        name: string;
      };
      briefing: {
        id: string;
        name: string;
        url: string;
      };
      accountManagers: {
        id: string;
        name: string;
        profile_picture: string;
      }[];
      influencers: {
        id: string;
        name: string;
        profile_picture: string;
        has_variable: boolean;
      }[];
      disclaimers: {
        id: string;
        platform: string;
        disclaimer: string;
      }[];
      descriptions: {
        id: string;
        description: string;
        platform: string;
      }[];
    };
  };
};

export const SingleCampaignSettingsTab = () => {
  const [campaignId]: [string] = useOutletContext();
  const { t } = useTranslation('common');
  const { refetchLateral, refetchTabs } = useContext(RefetchLateral);
  const [hasVariablePricing, setHasVariablePricing] = useState(false);

  const methods = useForm<Settings['campaignSettings']['data']>();
  const {
    control,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { dirtyFields },
  } = methods;

  const {
    fields: descriptions,
    append: appendDescription,
    remove: removeDescription,
    update: updateDescription,
  } = useFieldArray({
    name: 'descriptions',
    control,
  });

  const {
    fields: disclaimers,
    append: appendDisclaimer,
    remove: removeDisclaimer,
    update: updateDisclaimer,
  } = useFieldArray({
    name: 'disclaimers',
    control,
  });

  const [dirty, setDirty] = useState(false);
  const { refetch } = useQuery<Settings>(GET_SETTINGS, {
    variables: {
      campaignId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const info = data.campaignSettings.data;
      setHasVariablePricing(info.influencers.some((i) => i.has_variable));
      reset({
        ...info,
        month: info.month
          ? dayjs(info.month, 'MM/YYYY').format('MM/DD/YYYY')
          : '',
        influencers: info.influencers.map((i) => ({
          value: i.id,
          id: i.id,
        })) as any,
        accountManagers: info.accountManagers?.map((i) => ({
          value: i.id,
          id: i.id,
        })) as any,
      });
    },
  });
  const addDescription = () => {
    appendDescription({
      id: Math.floor(Math.random() * 1000).toString(),
      description: '',
      platform: '',
    });
  };

  const addDisclaimer = () => {
    appendDisclaimer({
      id: Math.floor(Math.random() * 1000).toString(),
      disclaimer: '',
      platform: '',
    });
  };

  const deleteDescription = (index: number) => {
    removeDescription(index);
  };

  const deleteDisclaimer = (index: number) => {
    removeDisclaimer(index);
  };

  const { notifySaveSuccess, notifySaveError } = useNotification();
  const [updateCampaign, { loading }] = useMutation(UPDATE_CAMPAIGN);

  const onCompleted = () => {
    notifySaveSuccess();
    refetch();
    setDirty(false);
    reset({}, { keepValues: true });
    refetchLateral();
    refetchTabs();
  };

  const handleSave = (data: Settings['campaignSettings']['data']) => {
    const headers: any = {
      'x-apollo-operation-name': 'setCampaignSettings',
    };
    updateCampaign({
      variables: {
        campaignId,
        name: data.name,
        month: data.month ? dayjs(data.month).format('MM/YYYY') : '',
        descriptions: data.descriptions.map((description) => {
          return {
            description: description.description,
            platform: description.platform,
          };
        }),
        disclaimers: data.disclaimers.map((disclaimer) => {
          return {
            disclaimer: disclaimer.disclaimer,
            platform: disclaimer.platform,
          };
        }),
        money: Number(data.money),
        currency: data.currency,
        brandId: data.brand.id,
        influencers: data.influencers
          .map((influencer: any) => influencer.value)
          .filter((id) => id),
        accountManagers: data.accountManagers
          .map((accountManager: any) => accountManager.value)
          .filter((id) => id),
      },
      context: {
        headers,
      },
      onCompleted: () => {
        onCompleted();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  usePrompt({
    isDirty: Object.keys(dirtyFields).length > 0 || dirty,
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="flex lg:flex-row flex-col gap-5">
          <TextInput
            type="text"
            label={t('singleCampaign.campaignName')}
            selectedValue={watch('name')}
            onSelectChange={(value) =>
              setValue('name', value as string, { shouldDirty: true })
            }
          />
          <div className="w-full">
            <UserDropdown
              control={control}
              name="brand.id"
              label={t('campaigns.createClient')}
              placeholder={t('campaigns.createClientPlaceholder')}
              validation={{ required: t('campaigns.clientRequired') }}
              type="brand"
              isClearable={false}
              // error={errors.client?.message}
            />
          </div>
        </div>
        <div className="flex lg:flex-row flex-col mt-5 gap-5 items-start">
          <div className="flex flex-row gap-x-2 items-center">
            <MoneyInput
              confirmPopup={true}
              label={t('singleCampaign.totalAmount')}
              name="money"
              currentCurrency={watch('currency')}
              control={control}
              currencySelect={true}
            />
            {hasVariablePricing && (
              <p className="flex-none mt-8 text-sm">
                {t('singleCampaign.variables')}
              </p>
            )}
          </div>
          <div className="flex items-center">
            <DatePickerInput
              name="month"
              monthYearPicker
              isClearable
              label={t('singleCampaign.campaignMonth')}
              control={control}
              fullSpace
            />
          </div>
        </div>
        <div className="flex flex-col gap-1 mt-5">
          <UserDropdownList
            type="influencer"
            accessName="influencers"
            label={t('singleCampaign.addinfluencers')}
            accessKey="value"
            addText={t('campaigns.createInfluencersAdd')}
            placeholderSelects={t('campaigns.createInfluencersPlaceholder')}
            control={control}
            minLength={0}
            trashIconClassname="fill-current text-red-status hover:text-influentia-light-blue"
            buttonClassname="hover:text-influentia-light-blue"
          />
        </div>
        <div className="flex flex-col gap-1 mt-5">
          <UserDropdownList
            type="influentia"
            accessName="accountManagers"
            label={t('campaigns.createAccountManagers')}
            accessKey="value"
            addText={t('campaigns.createAccountManagersAdd')}
            placeholderSelects={t('campaigns.createAccountManagersPlaceholder')}
            control={control}
            minLength={0}
            trashIconClassname="fill-current text-red-status hover:text-influentia-light-blue"
            buttonClassname="hover:text-influentia-light-blue"
          />
        </div>
        <div className="flex flex-col gap-1 mt-5">
          <label className="my-2 block text-[14px] font-[600]">
            {t('singleCampaign.disclaimer')}
          </label>
          {disclaimers &&
            disclaimers.map((disclaimer, index) => (
              <DisclaimerPlatforms
                key={disclaimer.id}
                disclaimer={disclaimer}
                updateDisclaimer={(d) => updateDisclaimer(index, d)}
                deleteDisclaimer={() => deleteDisclaimer(index)}
              />
            ))}
          <div className="w-full flex items-center justify-center">
            <Button
              style="light-blue-underline"
              text={t('singleCampaign.addDisclaimer')}
              type="button"
              customClassnames="hover:text-influentia-light-blue"
              id="addInfluencer"
              iconLeft={<CheckBoxReferralIcon />}
              onClick={() => addDisclaimer()}
            />
          </div>
        </div>
        <div className="flex flex-col gap-1 mt-5">
          <label className="my-2 block text-[14px] font-[600]">
            {t('singleCampaign.description')}
          </label>
          {descriptions &&
            descriptions.map((description, index) => (
              <DescriptionPlatforms
                key={description.id}
                description={description}
                updateDescription={(d) => updateDescription(index, d)}
                deleteDescription={() => deleteDescription(index)}
              />
            ))}
          <div className="w-full flex items-center justify-center">
            <Button
              style="light-blue-underline"
              text={t('singleCampaign.addDescription')}
              type="button"
              customClassnames="hover:text-influentia-light-blue"
              id="addInfluencer"
              iconLeft={<CheckBoxReferralIcon />}
              onClick={() => addDescription()}
            />
          </div>
        </div>
        <div className="mt-5">
          <Button
            style="light-blue"
            text={t('global.save')}
            loading={loading}
            type="submit"
            customClassnames="px-16"
          />
        </div>
      </form>
    </FormProvider>
  );
};
