import type {
  CampaignStatusType,
  ContentStatusType,
  InfluencerCampaignStatusType,
  InvoicesStatusType,
  PieceStatusType,
  PlatformType,
} from '../types';

export const ROLES = {
  brand: 'Brand',
  influencer: 'Influencer',
  influentia: 'Influentia',
};

export const SUBROLES = {
  operationsManager: 'Operations Manager',
  accountManager: 'Account Manager',
  external: 'External',
};

export const INFLUENTIA_USER_SUBROLES = [
  'Operations Manager',
  'Account Manager',
  'External',
];

export const CAMPAIGN_USER_STATUS: InfluencerCampaignStatusType[] = [
  'not_send',
  'active',
  'analysis',
  'facturation',
  'canceled',
  'finished',
];

export const ACTION_STATUS: PieceStatusType[] = [
  'tobe_scheduled',
  'script_upload',
  'script_validation',
  'preview_upload',
  'preview_validation',
  'scheduled',
  'canceled',
  'published',
];

export const CAMPAIGN_STATUS: CampaignStatusType[] = [
  'facturation',
  'pending',
  'active',
  'finished',
  'canceled',
];

export const CAMPAIGN_RECURRENT_STATUS: CampaignStatusType[] = [
  'pending',
  'active',
  'finished',
  'canceled',
];

export const CONTENT_STATUS: ContentStatusType[] = [
  'upload_pending',
  'internal_validation',
  'external_validation',
  'modification_pending',
  'validated',
  'old',
];

export const INVOICE_STATUS: InvoicesStatusType[] = ['payment_pending', 'paid'];

export const PLATFORMS: PlatformType[] = [
  'Youtube',
  'Instagram',
  'Telegram',
  'Substack',
  'TikTok',
  'Twitter',
  'Blog',
];

export const DEFAULT_PAGE_SIZE = 10;
export const PAGE_SIZES = [5, 10, 20, 30, 50];

export const ACTION_TYPES: Record<PlatformType, string[]> = {
  Youtube: ['Dedicated_Video', 'Mention', 'Semi_Dedicated_Video', 'Other'],
  Instagram: ['Reel', 'Story', 'Other'],
  TikTok: ['Video', 'Other'],
  Twitter: ['Tweet', 'Thread', 'Other'],
  Substack: ['Sponsored_Post', 'Dedicated_Post', 'Other'],
  Blog: ['Dedicated_Post', 'Sponsored_Post', 'Email', 'Other'],
  Telegram: ['Message', 'Story', 'Other'],
};

export const CURRENCY_TYPES = ['€', '$'];
export const ACTION_VARIABLE_PRICE_TYPE = [
  'CPM',
  'CPL',
  'CPA',
  'Revenue Share',
];
export const CAMPAIGN_VARIABLE_PRICE_TYPE = [
  'No',
  'CPM',
  'CPL',
  'CPA',
  'Revenue Share',
  'Per Action',
];

export const LANGUAGES = ['en', 'es', 'de'];
export const NOTIFICATIONS: Record<string, any> = {
  campaigns: {
    notifications: {
      'new-campaign-note': {
        roles: ['Influencer', 'Brand', 'Influentia'],
      },
      'influencer-added-campaign': {
        roles: ['Influentia'],
      },
      'campaign-canceled': {
        roles: ['Influencer'],
      },
    },
  },
  dates: {
    notifications: {
      'define-campaign-post-date-range': {
        roles: ['Influentia', 'Brand'],
      },
      'set-campaign-post-date-range': {
        roles: ['Influentia', 'Influencer'],
      },
      'update-campaign-post-date-range': {
        roles: ['Influentia'],
      },
      'post-date-defined': {
        roles: ['Influencer', 'Brand', 'Influentia'],
      },
      'post-date-updated': {
        roles: ['Influencer', 'Brand', 'Influentia'],
      },
    },
  },
  content: {
    notifications: {
      'script-pending-influentia': {
        roles: ['Influentia'],
      },
      'preview-pending-influentia': {
        roles: ['Influentia'],
      },
      'script-pending-influencer': {
        roles: ['Influencer'],
      },
      'preview-pending-influencer': {
        roles: ['Influencer'],
      },
      'script-pending-validation-influentia': {
        roles: ['Influentia'],
      },
      'preview-pending-validation-influentia': {
        roles: ['Influentia'],
      },
      'script-declined': {
        roles: ['Influencer', 'Influentia'],
      },
      'preview-declined': {
        roles: ['Influencer', 'Influentia'],
      },
      'new-validation-message': {
        roles: ['Influencer', 'Influentia', 'Brand'],
      },
      'script-pending-validation-brand': {
        roles: ['Brand'],
      },
      'preview-pending-validation-brand': {
        roles: ['Brand'],
      },
      'script-validated-influentia': {
        roles: ['Influentia'],
      },
      'preview-validated-influentia': {
        roles: ['Influentia'],
      },
      'script-validated-influencer': {
        roles: ['Influencer'],
      },
      'preview-validated-influencer': {
        roles: ['Influencer'],
      },
      'briefing-uploaded': {
        roles: ['Influencer', 'Influentia'],
      },
      'campaign-published': {
        roles: ['Marca', 'Influentia'],
      },
    },
  },
  invoices: {
    notifications: {
      'new-invoice-influentia': {
        roles: ['Influentia'],
      },
      'paid-invoice': {
        roles: ['Influencer'],
      },
      'new-invoice-brand': {
        roles: ['Brand'],
      },
    },
  },
};

export const PERMISSIONS = {
  // Resources
  CREATE_RESOURCES: 'create:resources',
  DELETE_RESOURCES: 'delete:resources',
  READ_RESOURCES: 'read:resources',
  // Brand
  DELETE_BRAND: 'delete:brand',
  CREATE_BRAND: 'create:brand',
  UPDATE_BRAND: 'update:brand',
  READ_BRAND: 'read:brand',
  // Action
  UPDATE_ACTION: 'update:action',
  // File
  UPLOAD_FILE: 'upload:file',
  DELETE_FILE: 'delete:file',
  // Campaign
  DELETE_CAMPAIGN: 'delete:campaign',
  READ_CAMPAIGN: 'read:campaign',
  CREATE_CAMPAIGN: 'create:campaign',
  UPDATE_CAMPAIGN: 'update:campaign',
  CREATE_CAMPAIGN_USER: 'create:campaignuser',
  READ_CAMPAIGN_USER: 'read:campaignuser',
  DELETE_CAMPAIGN_USER: 'delete:campaignuser',
  UPDATE_CAMPAIGN_USER: 'update:campaignuser',
  SEND_INVITE: 'create:sendinvite',
  READ_CAMPAIGN_SETTINGS: 'read:campaignsettings',
  // Content
  UPDATE_CONTENT: 'update:content',
  CREATE_CONTENT: 'create:content',
  DELETE_CONTENT: 'delete:content',
  // Dates
  READ_DATES: 'read:dates',
  // Invoices
  READ_INVOICES: 'read:invoices',
  UPDATE_INVOICE: 'update:invoice',
  DELETE_INVOICE: 'delete:invoice',
  CREATE_INVOICE: 'create:invoice',
  // Metrics
  CREATE_METRIC: 'create:metric',
  DELETE_METRIC: 'delete:metric',
  READ_METRIC: 'read:metric',
  // Note
  CREATE_NOTE: 'create:note',
  // Social Network
  UPDATE_SOCIAL_NETWORK: 'update:socialnetwork',
  // Timeline
  CREATE_TIMELINE: 'create:timeline',
  // User
  READ_USER: 'read:user',
  READ_PROFILE: 'read:profile',
  UPDATE_USER: 'update:user',
  CREATE_USER: 'create:user',
  DELETE_USER: 'delete:user',
  READ_INFLUENCER: 'read:influencer',
  CREATE_INFLUENCER: 'create:influencer',
  // Referral
  UPDATE_REFERRAL: 'update:referral',
  CREATE_REFERRAL: 'create:referral',
  // Notification
  UPDATE_NOTIFICATIONS: 'update:notifications',
  // Campaign Recurrent
  DELETE_CAMPAIGN_RECURRENT: 'delete:campaignrecurrent',
  READ_CAMPAIGN_RECURRENT: 'read:campaignrecurrent',
  CREATE_CAMPAIGN_RECURRENT: 'create:campaignrecurrent',
  UPDATE_CAMPAIGN_RECURRENT: 'update:campaignrecurrent',
  CREATE_CAMPAIGN_USER_RECURRENT: 'create:campaignuserrecurrent',
  READ_CAMPAIGN_USER_RECURRENT: 'read:campaignuserrecurrent',
  DELETE_CAMPAIGN_USER_RECURRENT: 'delete:campaignuserrecurrent',
  UPDATE_CAMPAIGN_USER_RECURRENT: 'update:campaignuserrecurrent',
} as const;
