import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { AddUserIcon, TrashIcon } from '../../assets';
import { GET_USERS } from '../../graphql/user/query';
import type { ResponseDataUsersList } from '../../types';
import { Button } from '../common/Button';
import { Error } from '../common/Error';
import { ModalPortal } from '../common/Portal';
import { UsersImages } from '../images/UserImages';
import { InfluencerPopUp } from '../popups/InfluencerPopUp';
import { GenericDropdown } from './GenericDropdown';

type UserDropdownProps = {
  type: 'influentia' | 'influencer';
  validation?: any;
  label?: string;
  accessName: string;
  accessKey: string;
  addText: string;
  placeholderSelects?: string;
  control: any;
  error?: string;
  minLength?: number;
  trashIconClassname?: string;
  buttonClassname?: string;
};

export const UserDropdownList = ({
  type,
  label,
  validation,
  accessName,
  accessKey,
  addText,
  placeholderSelects,
  error,
  minLength = 1,
  trashIconClassname,
  buttonClassname,
}: UserDropdownProps) => {
  const { setValue, control } = useFormContext(); // Get setValue from useFormContext
  const { fields, append, update, remove } = useFieldArray<
    Record<string, any>,
    any,
    'value' | 'id'
  >({
    control,
    name: accessName,
    rules: {
      validate: (f) => f.filter((i) => (i as any).value).length >= minLength,
    },
  });
  const [createdUser, setCreatedUser] = useState<string | null>(null); // To handle new user creation

  const [options, setOptions] =
    useState<{ value: string; label: string; image?: string }[]>();

  const [getUsers, { refetch, loading: loadingUsers }] =
    useLazyQuery<ResponseDataUsersList>(GET_USERS);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (type && !options) {
      if (type === 'influentia')
        getUsers({
          variables: { role: 'Influentia' },
          notifyOnNetworkStatusChange: true,
          onCompleted: (data) =>
            setOptions(
              data.users.data.map((u) => ({
                label: u.name,
                value: u.id,
                image: u.profile_picture || '',
              })),
            ),
        });
      else if (type === 'influencer')
        getUsers({
          variables: { role: 'Influencer' },
          notifyOnNetworkStatusChange: true,

          onCompleted: (data) =>
            setOptions(
              data.users.data.map((u) => ({
                label: u.name,
                value: u.id,
                image: u.profile_picture || '',
              })),
            ),
        });
    }
  }, [type]);

  useEffect(() => {
    if (createdUser) {
      const emptyFieldIndex = fields.findIndex((field) => !field.value);
      if (emptyFieldIndex !== -1) {
        setValue(`${accessName}.${emptyFieldIndex}.${accessKey}`, createdUser);
        update(emptyFieldIndex, { value: createdUser });
      }
    }
  }, [createdUser]);

  return (
    <div>
      {label && (
        <label htmlFor={label} className="my-2 block text-[14px] font-[600]">
          {label}
        </label>
      )}
      <div className="flex flex-col gap-y-2">
        {fields.map((v, i) => (
          <div className="flex flex-row gap-x-2" key={v.id}>
            <GenericDropdown
              isSearchable={true}
              id={`influencerDropdown${i}`}
              control={control}
              placeholder={placeholderSelects}
              name={`${accessName}.${i}.${accessKey}`}
              options={
                options?.filter(
                  (o) =>
                    !fields.map((f) => f.value).includes(o.value) ||
                    o.value === v.value,
                ) || []
              }
              onChangeManual={(val) => {
                update(i, { value: val.value });
              }}
              validation={validation}
              isLoading={loadingUsers}
              isClearable={false}
              formatLabelOption={({
                label: labelOption,
                image,
              }: {
                label: string;
                image?: string;
              }) => (
                <div className="flex flex-row items-center gap-x-2">
                  <UsersImages
                    size="size-[20px]"
                    userCount={1}
                    userImages={[image || '']}
                    influencers={type === 'influencer'}
                  />
                  <span>{labelOption}</span>
                </div>
              )}
            />
            {!v.value && type === 'influencer' && (
              <div
                className="w-[46px] h-[41px] bg-influentia-light-blue flex items-center justify-center rounded-[4px] cursor-pointer"
                onClick={() => setOpen(true)}
              >
                <AddUserIcon className="text-dark-blue" />
              </div>
            )}
            <Button
              text=""
              style="black-no-bg"
              customClassnames="!px-2"
              iconLeft={<TrashIcon className={trashIconClassname} />}
              onClick={() => remove(i)}
            />
          </div>
        ))}
        {open && (
          <ModalPortal>
            <InfluencerPopUp
              setOpen={setOpen}
              redirect={false}
              refetch={() => {
                refetch();
              }}
              setCreatedInfluencer={setCreatedUser} // Use setCreatedUser
            />
          </ModalPortal>
        )}

        {error && <Error error={error} />}
        <div className="w-full flex items-center justify-center">
          <Button
            style="light-blue-underline"
            text={addText}
            customClassnames={buttonClassname}
            id="addInfluencer"
            iconLeft={<AddUserIcon />}
            onClick={() => append({ value: '' })}
          />
        </div>
      </div>
    </div>
  );
};
